@import "../../colors.scss";
.appcontainer {
  width: 100%;
  height: 100%;

  .customnavbar {
  }
}

.homebody {
  background: $lightSkyColor;
  padding: 30px 30px 0px 30px;
  height: calc(100vh - 7vh);
  overflow-y: auto;
}
