$bluecolor: #005ebc;
$darkbluecolor: #004a94;
$blackcolor: #191f22;
$lightblackcolor: #20282c;
$tablebgcolor: #0087fd;
$skybluecolor: #0087fd;
$color-white-1: #ffffff;
$color-gray-1: #e5e5e5;
$lightGreenColor: #02c39a;
$darkGreenColorOne: #0a997a;
$cardGreenColor: #48b265;
$cardGreenColorDark: #2a9146;
$cardBlueColor: #3185fc;
$cardBlueColorDark: #1464d3;
$cardGreyColorDark: #403f4c;
$cardOrangeColor: #f9a03f;
$cardOrangeColorDark: #d08122;
$cardPurpleColor: rgb(174, 11, 174);
$cardPurpleColorDark: rgb(126, 13, 126);
$darkGreenColor: #403f4c;
$darkGrayOne: #f2f2f2;
$greenColor: #02c39a;
$color-gray-2: #c4c4c4;
$deleteRedColor: #dc3545;
$rowBgColor: #ffe193;
$Purple: #a802c3;
$Accent-Green: #02c39a;
$Red: #e84855;
$RedDark: #c23440;
$SecondaryBlue: #005ebc;
$lightBlue: #3185fc;
$DarkBlue: #1a63ca;
$DarkRed: #b62e39;
$AudioClickRed: #c43743;
$AudioHoverRed: #e84855;
$LightRed: #050303;
$color-gray-3: rgba(64, 63, 76, 0.31);
$color-gray-4: #5a5a5a;
$newButtonSucces: #48b265;
$newButtonSuccesDark: #218838;
$color-gray-5: #f1f1f1;
$lightSkyColor: #eaeef3;
$ScienceBlue: #005ebc;
$dividerGray: #eeeeee;
$sideActiveColor: #3185fc;
$cropperModalToggler: #3185fc;
$inputPlacHolderGrey: #b7b7b7;
$darkBlack: #000000;
$deleteSvg: #ccd0d9;
$tealColor: #009ed0;
$tulip: #f9a03f;
$previewType1: #6699ff;
$previewType2: #e84855;
$previewType3: #218838;
$DustyBlack: #5a5a5a;
$pink-color-1: #ec368d;
