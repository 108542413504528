@import "../../colors.scss";
.navcontainer {
  display: flex;
  padding: 20px 0px 20px 0px;
  align-items: center;
  justify-content: space-between;
  background-color: $bluecolor;
  height: 50px;

  .sidebaricon {
    cursor: pointer;
    height: 75px;
    object-fit: contain;
  }

  .logouticon {
    width: auto;
    height: 75px;
    object-fit: contain;
    cursor: pointer;
  }
}
