@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

.carousel.carousel-slider {
  overflow: unset !important;
}

.carousel-wrapper {
  border: 2px solid #adb5bd;
  border-radius: 7px;
  padding: 10px;
  width: 210px !important;
  height: 210px !important;
  margin-left: 3rem;
}

.control-dots {
  transform: translateY(10px);
}

.dot {
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
  background: #eeeeee;
}

.dot.selected {
  background-color: #3185fc !important;
}
.selected {
  background-color: #fff !important;
}
.carousel-container {
  margin-bottom: 10px;
}

.dropify-wrapper {
  border: 2.5px dashed #424242 !important;
  border-radius: 10px;
  color: #adb5bd;
  width: 210px !important;
  height: 120px !important;
  padding: 2rem !important;
}

.dropify-wrapper .dropify-message p {
  font-size: 15px;
  color: #3185fc;
  font-weight: bold;
}

.dropify-wrapper .dropify-message .file-icon {
  color: #3185fc !important;
}

.dropify-preview {
  background-color: inherit !important;
}

.file-icon::before {
  font-weight: 700 !important;
}

/* Rangle Slider */
.rangeslider {
  background: #f6f6f5 !important;
}

.rangeslider-horizontal {
  width: 500px;
  height: 10px;
}

.rangeslider__fill {
  background-color: unset !important;
}
.rangeslider__handle {
  background: #3185fc !important;
  border: none !important;
  box-shadow: unset !important;
  outline: none;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px !important;
  position: relative;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: "";
  position: absolute;
  width: 0px !important;
  height: 0px !important;
  top: 6px;
  left: 6px;
  border-radius: 50% !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.rangeslider__handle-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.8rem;
  user-select: none;
}

#root,
html,
body {
  height: 100%;
}
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  outline: none;
}
