@import '../../colors.scss';

.sidebarContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  height: 100vh;
  background-color: $blackcolor;
  position: relative;
  z-index: 30;

  .logogroup {
    background-color: $darkbluecolor;
    height: 49px;
    font-weight: 700;
    font-size: 24px;
    color: white;
    text-align: center;
    vertical-align: middle !important;
    line-height: 50px;
    cursor: pointer;
  }

  .userinfogroup {
    margin-top: 10px;
    display: flex;
    padding: 30px;
    align-items: center;
    height: 96px;
    cursor: pointer;

    & > span:not(:first-child) {
      margin-left: -10px;
    }

    .userprofilepic img {
      width: 51px;
      height: 51px;
      border-radius: 50%;
    }

    .userdetails {
      padding-left: 10px;
      .name {
        font-weight: 500;
        font-size: 16px;
        color: white;
        line-height: 18.75px;
      }

      .title {
        margin-top: 5px;
        font-weight: 500;
        font-size: 10px;
        color: white;
        line-height: 11.75px;
      }
    }
  }

  // .bin img {
  //   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(349deg)
  //     brightness(303%) contrast(101%);
  // }

  .sidebarItems {
    background-color: $lightblackcolor;

    .itemcontainer {
      display: flex;
      height: 70px;
    }

    .itemcontainer img {
      width: 40px;
      height: auto;
    }

    .itemcontainer > a {
      font-weight: 500;
      font-size: 16px;
      color: white !important;
      text-decoration: none;
      line-height: 18.75px;
      width: 100%;
      padding: 10px 0px;
      padding-left: 20px;
      display: flex;
      align-items: center;

      &:link {
        background-color: $lightblackcolor;
      }

      &:hover {
        background-color: #565656;
      }

      &:active {
        background-color: $sideActiveColor;
      }
    }

    a.itemcontainer--active {
      background-color: $sideActiveColor !important;
      &:hover {
        background-color: $sideActiveColor !important;
      }
    }

    .itemcontainer > a > span {
      padding-left: 10px;
    }
  }

  .language-select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url('../../assets/images/Polygon.png') !important;
    background-repeat: no-repeat !important;
    background-position-x: 92% !important;
    background-position-y: 12px !important;

    &:focus {
      outline: 0;
    }
  }
}
